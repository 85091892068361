import styled from '@material-ui/core/styles/styled';
import { IconButton } from '@material-ui/core';

export const StyledNotification = styled('div')({
  display: 'flex',
});

export const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  color: `${theme.palette.common.white}`,
  width: '10px',
  height: '10px',
  marginLeft: 'auto',
}));
