import createTheme from '@material-ui/core/styles/createTheme';
import createPalette from '@material-ui/core/styles/createPalette';
import { getNameAbsTheme, getThemeCurrent } from '../../utils/themeConfig';

export const getPalette = () => {
  const APP_NAME_ABSOLUTE =
    getThemeCurrent() || getNameAbsTheme(window.APP_NAME);

  if (
    [
      'blue',
      'edgefx',
      'mfdf',
      'edgefxdemo',
      'crypto',
      'gfifx',
      'edgefxasia',
      'msri',
      'tpifx',
      'trustone',
      'nologo',
      'ips',
      'kb'
    ].includes(APP_NAME_ABSOLUTE)
  ) {
    return {
      primary: {
        main: '#FFFFFF',
        light: '#e6e6e6',
        lightAlloc: '#213653',
      },
      secondary: {
        main: '#2659AD',
        light: '#213653',
      },
      error: {
        main: '#d22c2b',
      },
      warning: {
        main: '#d8d827',
      },
      info: {
        main: '#5cb3fd',
        light: '#213653',
      },
      success: {
        main: '#1b871e',
      },
      common: {
        white: '#e6e6e6',
        black: '#000000',
        lightGray: '#D3D3D3',
        grayShade: '#7a7a7a',
      },
      background: {
        default: '#1a2a41',
        secondary: '#262626',
      },
      label: '#e6e6e6',
      dailyFx: {
        title: '#e6e6e6',
        pubDate: '#7a7a7a',
      },
    };
  }

  if (APP_NAME_ABSOLUTE === 'banorte') {
    return {
      primary: {
        main: '#ec0029',
        light: '#e6e6e6',
      },
      secondary: {
        main: '#ec0029',
      },
      error: {
        main: '#d22c2b',
      },
      warning: {
        main: '#d8d827',
      },
      info: {
        main: '#ec0029',
      },
      success: {
        main: '#1b871e',
      },
      common: {
        white: '#e6e6e6',
        black: '#000000',
        lightGray: '#D3D3D3',
        grayShade: '#7a7a7a',
      },
      background: {
        default: '#FFF',
        secondary: '#e1e1e1',
      },
      colorTextBody: 'black !important',
      borderInput: '#ec0029',
      label: '#000000',
      dailyFx: {
        title: '#000000',
        pubDate: '#7a7a7a',
      },
    };
  }

  if (['blueLight', 'edgefxlight', 'shinhan', 'hanabank'].includes(APP_NAME_ABSOLUTE)) {
    return {
      primary: {
        main: '#00a8e8',
        light: '#e6e6e6',
      },
      secondary: {
        main: '#2659ad',
      },
      error: {
        main: '#d22c2b',
      },
      warning: {
        main: '#d8d827',
      },
      info: {
        main: '#5cb3fd',
      },
      success: {
        main: '#1b871e',
      },
      common: {
        white: '#e6e6e6',
        black: '#000000',
        lightGray: '#D3D3D3',
        grayShade: '#7a7a7a',
      },
      background: {
        default: '#FFF',
        secondary: '#262626',
      },
      colorTextBody: 'black !important',
      borderInput: '#1a2a41',
      label: '#000000',
      dailyFx: {
        title: '#00a8e8',
        pubDate: '#7a7a7a',
      },
    };
  }

  return {
    primary: {
      main: '#e46916',
      light: '#313131',
    },
    secondary: {
      main: '#e46916',
      light: '#0d0d0d',
    },
    error: {
      main: '#d9534f',
    },
    warning: {
      main: '#d8d827',
    },
    info: {
      main: '#5cb3fd',
      light: '#262626',
    },
    success: {
      main: '#1b871e',
    },
    common: {
      white: '#e6e6e6',
      black: '#000000',
      lightGray: '#D3D3D3',
      grayShade: '#7a7a7a',
    },
    background: {
      default: '#0d0d0d',
      secondary: '#262626',
    },
    label: '#e6e6e6',
    dailyFx: {
      title: '#e46916',
      pubDate: '#e6e6e6',
    },
  };
};

export const getTheme = (palette) =>
  createTheme({
    overrides: {
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: 'white',
          },
          color: 'white !important',
        },
        focused: {},
      },
      MuiFormControlLabel: {
        root: {
          color: palette.primary.main,
        },
        '&$disabled': {
          color: palette.primary.light,
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: palette.borderInput || palette.primary.light,
        },
      },
      MuiInputBase: {
        root: {
          color: 'white !important',
          lineHeight: '1em !important',
          padding: 0,
        },
        inputMarginDense: {
          // paddingTop: '0px !important',
          // paddingBottom: '0px !important',
        },
        input: {
          width: '100% !important',
        },
      },
      MuiInput: {
        underline: {
          borderBottom: `1px solid ${palette.primary.light}`,
        },
      },
      MuiSelect: {
        icon: {
          color: palette.primary.light,
        },
      },
      MuiCard: {
        root: {
          backgroundColor: palette.background.default,
          border: `1px solid ${palette.primary.light}`,
        },
      },
      MuiFormControl: {},
      MuiFormHelperText: {
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
      MuiGrid: {
        item: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
      MuiTypography: {
        root: {
          width: '100% !important',
        },
      },
      MuiCheckbox: {
        root: {
          color: palette.primary.main,
        },
        '&$checked': {
          color: palette.primary.main,
        },
        colorSecondary: {
          '&$disabled': {
            color: palette.primary.main,
            opacity: 0.5,
          },
        },
      },
      MuiSvgIcon: {
        fontSizeLarge: {
          fontSize: '2em',
        },
      },
      MuiDivider: {
        root: {
          marginBottom: 16,
        },
        light: {
          backgroundColor: palette.primary.main,
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: palette.background.default,
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: palette.primary.light,
        },
        toolbarBtnSelected: {
          color: palette.primary.light,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: 'transparent',
          color: palette.primary.light,
        },
        dayLabel: {
          color: '#262626',
        },
      },
      MuiPickersDay: {
        day: {
          color: palette.secondary.main,
        },
        daySelected: {
          backgroundColor: palette.secondary.main,
          color: palette.common.white,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            color: palette.secondary.main,
          },
        },
        dayDisabled: {
          color: palette.common.lightGray,
          backgroundColor: 'transparent',
        },
        current: {
          color: palette.secondary.main,
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '10px',
        },
      },
    },
    palette: createPalette(palette),
    typography: {
      fontSize: 12,
      fontFamily: `"Gotham-Book", "Helvetica", "Arial", sans-serif`,
      h1: {
        textTransform: 'capitalize',
      },
      h2: {
        textTransform: 'capitalize',
      },
      h3: {
        textTransform: 'capitalize',
      },
      h4: {
        textTransform: 'capitalize',
      },
      h5: {
        textTransform: 'capitalize',
      },
      h6: {
        textTransform: 'capitalize',
      },
      body1: {
        fontSize: '0.80rem',
      },
    },
  });
