import { formatInTimeZone } from 'date-fns-tz';

import { httpOrigin } from '../configs/apiOrigin';
import { ymdDash } from '../configs/dateFormatStr';

const apiUrlOrigin = httpOrigin('/trader-rest-service/v1/');

export const getHistoricalOrders = ({
  dates,
  latestUpdates = false,
  organizationId,
  shadowAdmin,
  token,
  rateEngineAdmin,
}) =>
  new Promise((resolve) => {
    const promises = [];

    promises.push(
      fetch(`${apiUrlOrigin}historical-orders`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          dates,
          latestUpdates,
          rateEngineAdmin,
        }),
      })
        .then((res) => {
          if (!res.ok && res.status !== 200) {
            return [];
          }

          return res.json();
        })
        .catch(() => [])
    );

    promises.push(
      fetch(`${apiUrlOrigin}historical-shadow`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          dates,
          latestUpdates,
          organizationId,
          shadowAdmin,
        }),
      })
        .then((res) => {
          if (!res.ok && res.status !== 200) {
            return [];
          }
          return res.json();
        })
        .catch(() => [])
    );

    Promise.all(promises)
      .then((toJsonResponse) => Promise.all(toJsonResponse))
      .then((res) => {
        let result = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const group of res) {
          result = result.concat(group);
        }
        resolve(result);
      });
  });

export const getViewUsers = ({ viewers, token }) =>
  new Promise((resolve, reject) => {
    fetch(`${apiUrlOrigin}historical-viewers`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        views: viewers,
      }),
    })
      .then((res) => {
        if (!res.ok && res.status !== 200) {
          return [];
        }
        return res.json();
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch(() => resolve([]));
  });

export const getTodayOrders = ({
  organizationId,
  shadowAdmin,
  token,
  rateEngineAdmin,
}) =>
  new Promise((resolve) => {
    const promises = [];

    promises.push(
      fetch(`${apiUrlOrigin}today-orders`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          rateEngineAdmin,
        }),
      })
        .then((res) => {
          if (!res.ok && res.status !== 200) {
            return [];
          }
          return res.json();
        })
        .catch(() => [])
    );

    promises.push(
      fetch(`${apiUrlOrigin}today-shadow`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          organizationId,
          shadowAdmin,
          latestUpdates: false,
        }),
      })
        .then((res) => {
          if (!res.ok && res.status !== 200) {
            return [];
          }
          return res.json();
        })
        .catch(() => [])
    );

    Promise.all(promises).then(async (res) => {
      let result = [];

      if (res.length) {
        res.forEach((r) => {
          result = result.concat(r);
        });
      }

      resolve(result);
    });
  });

export const getPartialOrders = ({ viewers, token }) =>
  new Promise((resolve) => {
    fetch(`${apiUrlOrigin}historical-partials`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        views: viewers,
      }),
    })
      .then((res) => {
        if (!res.ok && res.status !== 200) {
          return [];
        }
        sessionStorage.setItem(
          'fetchPartialOrders',
          formatInTimeZone(new Date(), 'America/New_York', ymdDash)
        );
        return res.json();
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch(() => resolve([]));
  });
