/* global ENV */
import { httpOrigin } from '../configs/apiOrigin';
import multiCurrencyDefaultSettings from '../configs/multiCurrencyDefaultSettings';
import checkPlatforms from './platform';
import { resetTimeDefault } from '../configs/dateFormatStr';
import { layoutDomains } from '../constants';

export function restoreSettings() {
  if (ENV === 'development') {
    console.log('[USER SETTINGS] Sending restore user settings request');
  }
  localStorage.clear();
  const apiUrlOrigin = httpOrigin(`/trader-rest-service/v1/user-settings/all`);

  fetch(apiUrlOrigin, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `${sessionStorage.getItem('token')}`,
    },
  })
    .then((response) => {
      // sucessfull, do nothingmultiCurrencyDefaultSettings
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function resetSetting(key, domain) {
  if (ENV === 'development')
    console.log(
      '[USER SETTINGS] Sending new reset setting request',
      key,
      domain
    );
  if (layoutDomains.includes(domain))
    localStorage.removeItem(
      `_${sessionStorage.getItem('global_username')}_${domain}`
    ); // This is the only resettable setting atm

  const apiUrlOrigin = httpOrigin('/trader-rest-service/v1/user-settings');
  fetch(apiUrlOrigin, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `${sessionStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      key,
      domain,
    }),
  })
    .then((response) => {
      // sucessfull, do nothing
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function sendSetting(key, value, domain) {
  if (window?.socket?.readyState !== 1) return;

  window.socket.send(
    JSON.stringify({
      body: {
        msg_type: 'save-user-settings',
        data: {
          key,
          value,
          domain,
          token: `${sessionStorage.getItem('token')}`,
        },
      },
    })
  );
}

export function explodeSetting(path, value) {
  const keys = path.split('.');
  const last = keys.pop();
  const newObj = {};
  keys.reduce((o, k) => o[k] || {}, newObj)[last] = value;
  return newObj;
}

export function getDefaultSettings() {
  const appName = window.APP_NAME;

  return {
    clocks: true,
    expire_time: 5,
    initial_order_notification: false,
    lang: appName === 'edgefx' ? 'en' : 'es',
    sound: false,
    sound_viewer: false,
    notify_fixed: false,
    stop_order_opposite: false,
    inverse_rate_notification: false,
    instruments: {
      default_instrument_settings: {
        order_types: {
          LIMIT: true,
          MARKET: true,
          STOP_MARKET: false,
          STOP_LIMIT: false,
          ICEBERG: false,
          SLICER: false,
          FIXING: false,
          MAN_OFFSET: false,
          OCO: false,
        },
        global: {
          sound: false,
          slippage: {
            MARKET: 30,
            LIMIT: 0.2,
          },
        },
        trading_widget: {
          doubleClickOrder: true,
          showPeggOnTop: true,
          showSource: false,
          termCurrency: false,
          tradableLevels: true,
          selectedView: 0,
          amount: '1,000,000',
          baseAmount: '1,000,000',
          termAmount: '1,000,000',
        },
        market_maker: {
          lvls: [],
          lvls_values: [],
          fwd_points: {
            buy: '',
            sell: '',
          },
          price_limits: {
            min: '',
            max: '',
          },
          qtys: { buy: '', sell: '' },
          spreads: { buy: '', sell: '' },
          tbo: 5,
          duration_time: 5,
          expiration_time: 0,
          auto_replenish: true,
          stateAutoHedge: {
            profitTakingSpread: false,
            valueProfitTakingSpread: '0',
            stopLossSpread: false,
            valueStopLossSpread: '0',
            onShoreSpot: false,
            offShoreNDF: false,
          },
        },
      },
    },
    ladder_widget: { widgets: {} },
    multicurrency_widget: {
      globalAmountArray: [
        { value: 500000, abr: '500K' },
        { value: 1000000, abr: '1M' },
        { value: 2000000, abr: '2M' },
        { value: 3000000, abr: '3M' },
      ],
      ...(multiCurrencyDefaultSettings[appName] ||
        multiCurrencyDefaultSettings.default),
      boxes: [
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'sp',
          orderType: 'LIMIT',
          tif: 'IOC',
          amount: '1,000,000',
          isLadderFrac: false,
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'sp',
          orderType: 'LIMIT',
          tif: 'IOC',
          amount: '1,000,000',
          isLadderFrac: false,
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'sp',
          orderType: 'LIMIT',
          tif: 'IOC',
          amount: '1,000,000',
          isLadderFrac: false,
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'sp',
          orderType: 'LIMIT',
          tif: 'IOC',
          amount: '1,000,000',
          isLadderFrac: false,
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'sp',
          orderType: 'LIMIT',
          tif: 'IOC',
          amount: '1,000,000',
          isLadderFrac: false,
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'sp',
          orderType: 'LIMIT',
          tif: 'IOC',
          amount: '1,000,000',
          isLadderFrac: false,
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'sp',
          orderType: 'LIMIT',
          tif: 'IOC',
          amount: '1,000,000',
          isLadderFrac: false,
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'sp',
          orderType: 'LIMIT',
          tif: 'IOC',
          amount: '1,000,000',
          isLadderFrac: false,
        },
      ],
    },
    rfs_widget: {
      globalAmountArray: [
        { value: 500000, abr: '500K' },
        { value: 1000000, abr: '1M' },
        { value: 2000000, abr: '2M' },
        { value: 3000000, abr: '3M' },
      ],
      double_click: true,
      boxes: [
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'rfq',
          orderType: 'LIMIT',
          tif: 'FOK',
          rfsDate: '',
          rfsDateFar: '',
          rfsTenor: '',
          rfsTenorFar: '',
          amount: '1,000,000',
          isLadderFrac: false,
          rfsType: 'outright',
          uuid: '',
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'rfq',
          orderType: 'LIMIT',
          tif: 'FOK',
          rfsDate: '',
          rfsDateFar: '',
          rfsTenor: '',
          rfsTenorFar: '',
          amount: '1,000,000',
          isLadderFrac: false,
          rfsType: 'outright',
          uuid: '',
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'rfq',
          orderType: 'LIMIT',
          tif: 'FOK',
          rfsDate: '',
          rfsDateFar: '',
          rfsTenor: '',
          rfsTenorFar: '',
          amount: '1,000,000',
          isLadderFrac: false,
          rfsType: 'outright',
          uuid: '',
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'rfq',
          orderType: 'LIMIT',
          tif: 'FOK',
          rfsDate: '',
          rfsDateFar: '',
          rfsTenor: '',
          rfsTenorFar: '',
          amount: '1,000,000',
          isLadderFrac: false,
          rfsType: 'outright',
          uuid: '',
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'rfq',
          orderType: 'LIMIT',
          tif: 'FOK',
          rfsDate: '',
          rfsDateFar: '',
          rfsTenor: '',
          rfsTenorFar: '',
          amount: '1,000,000',
          isLadderFrac: false,
          rfsType: 'outright',
          uuid: '',
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'rfq',
          orderType: 'LIMIT',
          tif: 'FOK',
          rfsDate: '',
          rfsDateFar: '',
          rfsTenor: '',
          rfsTenorFar: '',
          amount: '1,000,000',
          isLadderFrac: false,
          rfsType: 'outright',
          uuid: '',
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'rfq',
          orderType: 'LIMIT',
          tif: 'FOK',
          rfsDate: '',
          rfsDateFar: '',
          rfsTenor: '',
          rfsTenorFar: '',
          amount: '1,000,000',
          isLadderFrac: false,
          rfsType: 'outright',
          uuid: '',
        },
        {
          instrument: 'UNSELECTED',
          instrumentId: 0,
          tenor: 'rfq',
          orderType: 'LIMIT',
          tif: 'FOK',
          rfsDate: '',
          rfsDateFar: '',
          rfsTenor: '',
          rfsTenorFar: '',
          amount: '1,000,000',
          isLadderFrac: false,
          rfsType: 'outright',
          uuid: '',
        },
      ],
    },
    pnl_widget: {
      group_by_pair: false,
      show_term_amount: false,
      reset_time: resetTimeDefault,
    },
    rfq_widget: {},
    rfq_blotter_widget: {},
    blotter_widget: {
      blotter_tab: checkPlatforms(['edgefx']) ? 'todayOrders' : 'todayTrades',
    },
    instruments_widget: {
      order: [],
    },
    client_pricing_widget: {
      sub_widgets: {},
    },
  };
}
