import React, { useEffect, useState, useCallback, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  USER_DETAILS,
  SET_AUTHENTICATION_TOKEN,
  USER_PERMISSIONS,
} from '../../actions/action_types';
import { httpOrigin } from '../../configs/apiOrigin';
import frontLogger from '../../utils/log';
import Loading from '../common/loading';

const log = frontLogger('RequireAuth');

type RequireAuthProps = {
  children: ReactNode;
};

const RequireAuth: React.FC = ({ children }: RequireAuthProps) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const authenticate = useCallback(async () => {
    const token = sessionStorage.getItem('token');
    const reloadAfterLogin = sessionStorage.getItem('reloadAfterLogin');

    if (!token) {
      log.warn('no token found');
      history.push('/');
      return;
    }

    if (reloadAfterLogin === 'true') {
      sessionStorage.removeItem('reloadAfterLogin');
      history.go(0);
      return;
    }

    try {
      const origin = httpOrigin('/trader-rest-service/v1/authenticate');
      const rawResponse = await fetch(origin, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ token }),
      });

      if (!rawResponse.ok && rawResponse.status !== 200) {
        log.warn('token no valid');
        history.push('/');
        return;
      }

      const response = await rawResponse.json();

      const {
        username,
        id_companies: idCompany,
        company_name: companyName,
        id_users: userId,
        rate_engine_enabled: rateEngineEnabled,
        shadow_admin: shadowAdmin,
        is_slave: isViewer,
        is_enable_websocket_routing: isEnableWebsocketRouting = false,
        permissions,
      } = response.user;

      sessionStorage.setItem('global_username', username);
      sessionStorage.setItem('global_org_id', idCompany);
      sessionStorage.setItem('AuthIToken', token);
      sessionStorage.setItem('global_organization', companyName);

      dispatch({
        type: USER_PERMISSIONS,
        payload: permissions,
      });

      dispatch({
        type: USER_DETAILS,
        payload: {
          username,
          userId,
          organization: companyName,
          organizationId: idCompany,
          rateEngineEnabled,
          shadowAdmin,
          isViewer,
          isEnableWebsocketRouting,
        },
      });

      dispatch({ type: SET_AUTHENTICATION_TOKEN, payload: token }); // it's a valid token. Set to the redux store

      setLoading(false);
      setAuthenticated(true);
    } catch (err) {
      console.error('error validating token', err);
      history.push('/');
    }
  }, [dispatch, history]);

  useEffect(() => {
    log.dev('starting authentication process');
    authenticate();
  }, [authenticate]);

  if (loading || !authenticated) return <Loading />;

  return <>{children}</>;
};

export default RequireAuth;
