import sortBy from 'lodash/sortBy';
import { WhiteLabels, WhiteLabelOption } from '~types/common';
import wls from '../configs/wl';

const wl: WhiteLabels = wls;

export const getPlatformInfo = (): string[] => {
  const currUserName = sessionStorage.getItem('global_username');
  const pfLookupKey = `_currentEnv_${currUserName}`;
  const platform = localStorage.getItem(pfLookupKey) || '';

  // 1. platform based on local storage setting
  if (platform) {
    return [pfLookupKey, platform];
  }

  // 2. no any setting yet, then get default one based on host
  const hostname = window?.location?.hostname?.split('.');
  const [first, second] = hostname;
  if (wl[first]) {
    return [pfLookupKey, first];
  }

  if (wl[second]) {
    return [pfLookupKey, second];
  }

  return [pfLookupKey, 'localhost'];
};

export const getWlOptions = (): WhiteLabelOption[] => {
  const wlOptions: WhiteLabelOption[] = sortBy(
    Object.keys(wl).map((pl: string) => ({
      id: pl,
      label: `${wl[pl].APP_TITLE} - ${pl}`,
    })),
    'label'
  );
  return wlOptions;
};

// platForms as array
// easy handle for multi platform support
const checkPlatforms = (platForms: string[]) =>
  platForms.includes((window.APP_NAME || '').toLowerCase());

export const getHost = () => {
  // logic for setting correct APP_NAME and APP_TITLE
  const [, host] = getPlatformInfo();
  return wl[host];
};

// handel different theme before / after login
export const themeFor = (appNameAbs: string[], beforeLogin = true) => {
  const appName = getHost()?.APP_NAME;
  if (beforeLogin) {
    return (
      appNameAbs.includes(appName) && sessionStorage.getItem('token') === null
    );
  }

  return (
    appNameAbs.includes(appName) && sessionStorage.getItem('token') !== null
  );
};

// handel customize login submit btn bg color
export const customizeLoginBtnBgColor = (
  appNameAbs: string[],
  color: string
) => {
  if (!appNameAbs.includes(getHost()?.APP_NAME)) {
    return {};
  }

  return { backgroundColor: color };
};

export default checkPlatforms;
